import { PASSWORD_REQUIREMENTS } from "./password-requirements";
import { ROUTES } from "./routes";
var PACKAGE_NAME = require("../package.json").name;
var clientLogoSrc = require("./resources/qclogo.svg");
// eslint-disable-next-line @typescript-eslint/no-var-requires
var topBannerSrc = require("./resources/top-banner.jpg");
export var CONFIG = {
    APP_NAME: "My Quilter Cheviot",
    ASSET_ALLOCATION_COLOR_COUNT: 11,
    BASE_PATH: "/" + PACKAGE_NAME,
    CLIENT_LOGO: {
        src: clientLogoSrc,
        slideMenu: { height: "40px", width: "auto", verticalOffset: "9px" },
        staticMenu: { height: "50px", width: "auto", verticalOffset: "9px" }
    },
    CONTACT_US: {
        EMAIL: "enquiries@quiltercheviot.com",
        TELEPHONE: {
            displayNumber: "020 7150 4000",
            internationalNumber: "+44-20-7150-4000"
        }
    },
    DATE_PICKER_MAX_OFFSET_MONTHS: null,
    DOCUMENTS: {
        DOWNLOAD_ERROR_TEXT: "There was a problem downloading this document. Please try again. If the problem persists, please contact us."
    },
    ERROR: {
        CONTACT_LINK: "https://quiltercheviot.com/contact-us/",
        FATAL_TEXT: "There was a problem accessing My Quilter Cheviot. Please try again. If the problem persists, please contact us."
    },
    FOOTER: {
        BODY_TEXT: [
            "Quilter Cheviot and Quilter Cheviot Investment Management are trading names of Quilter Cheviot Limited, Quilter Cheviot International Limited and Quilter Cheviot Europe Limited. Quilter Cheviot Limited is registered in England with number 01923571, registered office at Senator House, 85 Queen Victoria Street, London, EC4V 4AB. Quilter Cheviot Limited is a member of the London Stock Exchange, authorised and regulated by the UK Financial Conduct Authority and as an approved Financial Services Provider by the Financial Sector Conduct Authority in South Africa. Quilter Cheviot Limited has established a branch in the Dubai International Financial Centre (DIFC) with number 2084 which is regulated by the Dubai Financial Services Authority. Promotions of financial information made by Quilter Cheviot DIFC are carried out on behalf of its group entities.  Accordingly, in some respects the regulatory system that applies will be different from that of the United Kingdom. Quilter Cheviot International Limited is registered in Jersey with number 128676, registered office at 3rd Floor, Windward House, La Route de la Liberation, St Helier, JE1 1QJ, Jersey and is regulated by the Jersey Financial Services Commission and as an approved Financial Services Provider by the Financial Sector Conduct Authority in South Africa. Quilter Cheviot Europe Limited is regulated by the Central Bank of Ireland, and is registered in Ireland with number 643307, registered office at Hambleden House, 19-26 Lower Pembroke Street, Dublin D02 WV96."
        ],
        LINK_TEXT: "My Quilter Cheviot Terms & Conditions"
    },
    GTM_ID: "GTM-T2ZBL4Q",
    HEADER_BACKGROUND_IMAGE: {
        url: topBannerSrc,
        color: "#003643",
        opacity: 0.7
    },
    MAIN_MENU_BREAKPOINT: "1160px",
    PAPERLESS: {
        bespokeFormIntroText: [
            "My Quilter Cheviot provides clients with secure and immediate access to your financial reports.",
            "As we try to reduce Quilter Cheviot’s environmental footprint, we are moving towards making these available online by default."
        ]
    },
    PASSWORD_REQUIREMENTS: PASSWORD_REQUIREMENTS,
    ROUTES: ROUTES,
    SCREEN_READER_DESCRIPTIONS: {
        DATA: {
            STATEMENTS: "Cash transactions",
            TRANSACTIONS: "Stock transactions"
        }
    },
    SERVICE_WORKER_ENABLED: true,
    SESSION_IDLE: {
        MESSAGE: "Please note, your session will expire in less than 2 minutes. Please click below if you would like your session to continue.",
        TIMEOUT: 120000
    },
    SLIDER_MENU_ICON_TEXT: "More"
};
