/* istanbul ignore file */
import React from "react";
import ReactDOM from "react-dom";
import Bootstrap, { THEME_QC } from "@jhc-systems/client-portal-core/dist";
import { CONFIG } from "./config";
var VERSION = require("../package.json").version;
var app = document.getElementById("app");
if (!app) {
    throw new Error("Could not find element with id app to mount the application");
}
app.setAttribute("version", VERSION);
ReactDOM.render(React.createElement(Bootstrap, { config: CONFIG, theme: THEME_QC }), app);
