import { MenuIconId } from "./types";
var baseRoute = "/accounts";
export var ROUTES = {
    baseRoute: baseRoute,
    routes: [
        {
            title: "Summary",
            destination: [
                {
                    component: "AccountsPage",
                    icon: MenuIconId.WalletAccountBalance,
                    title: "Accounts",
                    to: baseRoute
                }
            ]
        },
        {
            title: "My investments",
            destination: [
                {
                    component: "InvestmentsPage",
                    icon: MenuIconId.Temple,
                    title: "My investments",
                    to: "/investments"
                },
                {
                    component: "TransactionsPage",
                    icon: MenuIconId.Costs,
                    title: "Stock transactions",
                    to: "/stock-transactions"
                },
                {
                    component: "StatementsPage",
                    icon: MenuIconId.ThreeSliders,
                    title: "Cash transactions",
                    to: "/cash-transactions"
                }
            ]
        },
        {
            title: "My documents",
            destination: {
                component: "DocumentsPage",
                to: "/documents"
            }
        },
        {
            title: "My account",
            destination: [
                {
                    component: "ChangePasswordPage",
                    icon: MenuIconId.PadlockClosed,
                    title: "Change password",
                    to: "/change-password"
                },
                {
                    component: "PaperlessPage",
                    icon: MenuIconId.DocumentFoldedCorner,
                    title: "Paperless statements",
                    to: "/paperless"
                }
            ]
        },
        {
            title: "Contact us",
            destination: {
                component: "ContactUsPage",
                to: "/contact-us"
            }
        },
        {
            title: "Help",
            destination: {
                component: "HelpPage",
                to: "/help"
            }
        }
    ]
};
