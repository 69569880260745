export var PASSWORD_REQUIREMENTS = {
    content: [
        {
            content: "a-z",
            description: "At least 1 lower case letter"
        },
        {
            content: "A-Z",
            description: "At least 1 upper case letter"
        },
        {
            content: "//!£$%&*?",
            description: "At least 1 special character"
        },
        {
            content: "0-9",
            description: "At least 1 number"
        }
    ],
    minLength: {
        minLength: 9,
        description: "At least 9 characters"
    }
};
