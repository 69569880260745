//TODO: import this from core package as JS not TS
export var MenuIconId;
(function (MenuIconId) {
    MenuIconId["Costs"] = "costs";
    MenuIconId["DonutSmall"] = "donut-small";
    MenuIconId["DocumentFoldedCorner"] = "document-folded-corner";
    MenuIconId["PadlockClosed"] = "padlock-closed";
    MenuIconId["Temple"] = "temple";
    MenuIconId["ThreeSliders"] = "three-sliders";
    MenuIconId["WalletAccountBalance"] = "wallet-account-balance";
})(MenuIconId || (MenuIconId = {}));
